import React from "react";

import connectWallet from "../../img/connect-wallet.png";
import nftMarketplace from "../../img/nft-marketplace.png";
import collectNft from "../../img/collect-nft.png";

interface Props {}

export default function HowToEarnSilver({}: Props): React.ReactElement<Props> {
    return (
        <div className="howToEarnSilver">
            <div className="howToEarnSilver-wrapper">
                <div className="bg-spotlight">
                    <div className="container text-center">
                        <div>
                            <h1 className="text-white">How to Earn Silver</h1>
                            <p className="text-white">
                                Ready to amplify your Punk experience? <br />
                                Follow these simple steps to validate, hold, and
                                reap the rewards of your Punk ownership.
                            </p>
                        </div>
                        <div className="row align-items-start card-container">
                            <div className="col-12 col-lg text-white bg-card rounded my-3 my-lg-0 mx-lg-3">
                                <img
                                    className="card-icon"
                                    src={connectWallet}
                                    alt="connect wallet"
                                    height="200"
                                />
                                <h5 className="card-title">Verify</h5>
                                <p>
                                    Prove you're part of the revolution! Connect
                                    your wallet now and validate your Punk
                                    ownership.
                                </p>
                            </div>
                            <div className="col-12 col-lg text-white bg-card rounded my-3 my-lg-0 mx-lg-3">
                                <img
                                    className="card-icon"
                                    src={nftMarketplace}
                                    alt="nft marketplace"
                                    height="200"
                                />
                                <h5 className="card-title">
                                    Hold the NFT in their wallet
                                </h5>
                                <p>
                                    Hold onto your Punk in your wallet and join
                                    the ranks of our dedicated Azero Punk
                                    community.
                                </p>
                            </div>
                            <div className="col-12 col-lg text-white bg-card rounded my-3 my-lg-0 mx-lg-3">
                                <img
                                    className="card-icon"
                                    src={collectNft}
                                    alt="collect nft"
                                    height="200"
                                />
                                <h5 className="card-title">Collect</h5>
                                <p>
                                    Time to reap the rewards! As a Punk holder,
                                    you'll earn exclusive benefits.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
